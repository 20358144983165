<template>
  <v-card
    elevation="2"
    :disabled="isDisabled"
  >
    <v-card-title>{{ title }}</v-card-title>
    <v-card-subtitle class="error--text pb-0">
      {{ $t('workInProgressSettingPriceAndCurrencyWillHaveNoEffectForNow') }}
    </v-card-subtitle>
    <v-card-subtitle>
      <v-icon
        left
        small
        style="vertical-align: text-top"
      >
        mdi-information
      </v-icon>{{ $t('giveAnOrderACustomAmount') }} <div>Note: This will only have an affect if you click on Pay.</div>
    </v-card-subtitle>
    <v-card-text>
      <v-text-field
        type="number"
        :label="$t('price')"
        :placeholder="$t('setCustomAmount')"
        v-model="customPrice"
      />
      <v-select
        :label="$t('currency')"
        :items="selectedItemCurrencies"
        v-model="selectedCurrency"
      />
    </v-card-text>
  </v-card>
</template>

<script>
	export default {
		name: "PriceCurrencySelection",
		props: {
			isDisabled: {
				type: Boolean
			},
			customTitle: {
				type: String,
				default: ''
			},
			selectedItem: {
				type: Object, 
				default: () => {}
			}
		},
		data() {
			return {
				customPrice: null,
				selectedCurrency: 'ISK',
			}
		},
		computed: {
			title() {
				if (this.customTitle) {
					return this.customTitle
				} else {
					return this.$t('price')
				}
			},
			selectedItemCurrencies() {
				if (this.selectedItem && this.selectedItem.price) {
					let currencies = []

					for (let i in this.selectedItem.price) {
						if (i === 'currency_code') {
							currencies.push(this.selectedItem.price[i])
						}
					}
					return currencies
				}
				return ['ISK']
			}
		},
		watch: {
			customPrice(value) {
				this.$emit('priceUpdated', value)
			},
			selectedCurrency(value) {
				this.$emit('currencyUpdated', value)
			}
		}
	}
</script>

<style scoped>

</style>